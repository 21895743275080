<template>
    <div>
        <top>
            <div class="cfff tc">
                <div class=" fs42 mt140 ">药店入驻快速通道</div>
                <div class="mt20">请填写关于药店入驻资料</div>
            </div>
        </top>
        <div class="rowjc " style="margin-top: -140px;">
            <div class="box">
                <div class="c888">药店名称</div>
                <el-input class="mt10 " placeholder="请输入药店名称" v-model="merchantName" clearable>
                </el-input>
                <div class="mt10 c888">统一社会信用代码</div>
                <el-input class="mt10" placeholder="请输入统一社会信用代码" v-model="creditCode" clearable>
                </el-input>
                <div class="mt10 c888">医疗等级</div>


                <el-select v-model="value" placeholder="请选择" class="w-100 mt10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>

                <div class="mt10 c888">法定代表人身份证号码</div>
                <el-input class="mt10" placeholder="请输入法定代表人身份证号码" maxlength="18" show-word-limit
                    v-model="personChargeNum" @input="personChargeNum=personChargeNum.replace(/[^0-9,X|x]/g,'')"
                    clearable>
                </el-input>
                <div class="mt10 c888">药店地址</div>
                <el-input class="mt10" placeholder="请输入药店地址" v-model="address" clearable>
                </el-input>
                <div class="mt10 c888">座机号</div>
                <el-input class="mt10" placeholder="请输入座机号" v-model="landlineNumber"
                    @input="landlineNumber=landlineNumber.replace(/[^0-9,-]/g,'')" clearable>
                </el-input>
                <div class="mt10 c888">手机号</div>
                <el-input class="mt10 tel" show-word-limit placeholder="请输入手机号" @input="tel=tel.replace(/[^0-9]/g,'')"
                    maxlength="11" v-model="tel" clearable>
                </el-input>
                <div class="mt10 c888">电子邮箱</div>
                <el-input class="mt10" placeholder="请输入电子邮箱" type="mail" v-model="mail" clearable>
                </el-input>
                <div class="mt10 c888">入驻单位简称</div>
                <el-input class="mt10" placeholder="请输入入驻单位简称" v-model="company" clearable>
                </el-input>
                <div class="mt10 c888">机构简称(500字以内)</div>
                <el-input class="mt10" maxlength="500" show-word-limit type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入机构简称" clearable v-model="organization">
                </el-input>
                <div class="mt10 c888">经办人姓名</div>
                <el-input class="mt10" @input="attentionName=attentionName.replace(/[^\u4e00-\u9fa5]{0,}$/g,'')"
                    placeholder="请输入经办人姓名" v-model="attentionName" clearable>
                </el-input>
                <div class="mt10 c888">经办人手机号码</div>
                <el-input @input="tel=tel.replace(/[^0-9]/g,'')" show-word-limit maxlength="11" class="mt10 tel"
                    placeholder="请输入经办人手机号码" v-model="attentionTel" clearable>
                </el-input>
                <div class="mt10 c888">营业执照</div>
                <el-upload class="avatar-uploader mt20" :action="$store.state.uploadImg" :show-file-list="false"
                    :on-success="(res)=>upOnload(res,'imageUrl')" :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="mt20 c888">资质证书</div>
                <el-upload class="avatar-uploader mt20" :action="$store.state.uploadImg" :show-file-list="false"
                    :on-success="(res)=>upOnload(res,'qualificationCertificate')" :before-upload="beforeAvatarUpload">
                    <img v-if="qualificationCertificate" :src="qualificationCertificate" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="mt20 c888">机构logo</div>
                <el-upload class="avatar-uploader mt20" :action="$store.state.uploadImg" :show-file-list="false"
                    :on-success="(res)=>upOnload(res,'logo')" :before-upload="beforeAvatarUpload">
                    <img v-if="logo" :src="logo" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="mt20 c888">身份证</div>
                <div class="rowic">
                    <div>
                        <el-upload class="avatar-uploader mt20 " :action="$store.state.uploadImg"
                            :show-file-list="false" :on-success="(res)=>upOnload(res,'frontCard')"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="frontCard" :src="frontCard" class="w300 h200">
                            <i v-else class="el-icon-plus avatar-uploader-iconA"></i>

                        </el-upload>
                        <div class="tc c888 mt3">身份证正面</div>
                    </div>
                    <div class="ml40">
                        <el-upload class="avatar-uploader mt20" :action="$store.state.uploadImg" :show-file-list="false"
                            :on-success="(res)=>upOnload(res,'backCard')" :before-upload="beforeAvatarUpload">
                            <img v-if="backCard" :src="backCard" class="w300 h200">
                            <i v-else class="el-icon-plus avatar-uploader-iconA"></i>

                        </el-upload>
                        <div class="tc mt3 c888">身份证反面</div>
                    </div>
                </div>
                <div class="mt20 c888">药店门面照片</div>
                <el-upload class="avatar-uploader mt20" :action="$store.state.uploadImg" :show-file-list="false"
                    :on-success="(res)=>upOnload(res,'image')" :before-upload="beforeAvatarUpload">
                    <img v-if="image" :src="image" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="mt20 c888">备注(500字以内)</div>
                <el-input class="mt10" maxlength="500" show-word-limit type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入备注" clearable v-model="remark">
                </el-input>
                <div class="rowjcic">
                    <div @click="submit" class="submit ">确定提交</div>
                </div>
            </div>
        </div>

        <div class="h80 w20"></div>
        <bottom></bottom>
    </div>
</template>
<script>
    import top from '@/components/top'
    import bottom from '@/components/common/bottom'
    export default {
        components: {
            top,
            bottom
        },
        data() {
            return {
                merchantName: '',//商家名称
                creditCode: '',//信用代码
                personCharge: '',//法定代表人
                personChargeNum: "",//法定代表人身份证号码
                address: '',//店铺地址
                landlineNumber: '',//座机号码
                tel: "",//手机号码
                mail: '',//电子邮箱
                company: '',//入驻单位简称
                organization: '',//机构简介
                attentionName: '',//经办人姓名
                attentionTel: '',//经办人手机号码
                imageUrl: '',//营业执照
                qualificationCertificate: "",//资质证书
                logo: '',//logo
                frontCard: '',//身份证正面
                backCard: '',//身份证反面
                image: "",//门店照片
                remark: "",
                options: [{
                    value: '选项1',
                    label: '1'
                }, {
                    value: '选项2',
                    label: '2'
                }, {
                    value: '选项3',
                    label: '3'
                }],
                value: ''



            }
        },
        mounted() {

        },
        methods: {
            upOnload(res, data) {
                console.log(res)
                console.log(data)
                if (res.code == 0) {
                    this[data] = res.data.path
                } else {
                    this.$message.error('上传失败');
                }
            },
            handleAvatarSuccess(res, type) {
                console.log(res)
                console.log(type)
                this.$message.success('上传成功');
            },
            submit() {//提交


                if (!this.merchantName) {
                    this.$message.error('请输入药店名称');
                    return false
                }
                if (!this.creditCode) {
                    this.$message.error('请输入统一社会信用代码');
                    return false
                }
                if (!this.value) {
                    this.$message.error('请选择医疗等级');
                    return false
                }
                if (!this.personCharge) {
                    this.$message.error('请输入法定代表人');
                    return false
                }
                if (!this.personChargeNum) {
                    this.$message.error('请输入法定代表人身份证号码');
                    return false
                }
                if (!this.address) {
                    this.$message.error('请输入药店地址');
                    return false
                }
                let landlineNumber = this.isZuo(this.landlineNumber)

                if (!landlineNumber) {
                    this.$message.error('请输入座机号码');
                    return false
                }
                let tel = this.isTel(this.tel)
                if (!tel) {
                    this.$message.error('请输入手机号码');
                    return false
                }
                let mail = this.isEmail(this.mail)

                if (!mail) {
                    this.$message.error('请输入电子邮箱');
                    return false
                }
                if (!this.company) {
                    this.$message.error('请输入入驻单位简称');
                    return false
                }
                if (!this.organization) {
                    this.$message.error('请输入机构简称');
                    return false
                }
                if (!this.attentionName) {
                    this.$message.error('请输入经办人姓名');
                    return false
                }
                let attentionTel = this.isTel(this.attentionTel)
                if (!attentionTel) {
                    this.$message.error('请输入经办人手机号码');
                    return false
                }
                if (!this.imageUrl) {
                    this.$message.error('请上传营业执照');
                    return false
                }
                if (!this.qualificationCertificate) {
                    this.$message.error('请上传资质证书');
                    return false
                }
                if (!this.logo) {
                    this.$message.error('请上传logo图');
                    return false
                }
                if (!this.frontCard) {
                    this.$message.error('请上传身份证正面');
                    return false
                }
                if (!this.backCard) {
                    this.$message.error('请上传身份证反面');
                    return false
                }
                if (!this.image) {
                    this.$message.error('请上传药店门面照');
                    return false
                }
                this.tijiao()
            },
            tijiao() {
                this.$axios({
                    url: 'uploadOrgan',
                    data: {
                        "name": this.merchantName,
                        "person": this.personCharge,
                        "address": this.address,
                        "landline": this.landlineNumber,
                        "mobile": this.tel,
                        "email": this.mail,
                        "etc": this.company,
                        "code": this.creditCode,
                        "content": this.organization,
                        "people": this.attentionName,
                        "phone": this.attentionTel,
                        "businessLicense": this.imageUrl,
                        "personCard": this.personChargeNum,
                        "qualificationCertificate": this.qualificationCertificate,
                        "logo": this.logo,
                        "frontCard": this.frontCard,
                        "backCard": this.backCard,
                        level: this.value,
                        "image": this.image,
                        "remark": this.remark
                    }
                }).then(res => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                    } else {
                        this.$message(res.data.msg);
                    }



                })
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                console.log(this.imageUrl)
            },
            beforeAvatarUpload(file) {

                const isLt2M = file.size / 1024 / 1024 < 2;


                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            isTel(s) {
                var length = s.length;
                if (length == 11 && /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1})|(14[0-9]{1})|)+\d{8})$/.test(s)) {
                    return true;
                } else {
                    return false;
                }
            },
            isEmail(strEmail) {
                if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(strEmail)) {
                    return false;
                }
                else {
                    return true;
                }
            },
            isZuo(mobile) {//座机号码判断
                console.log(mobile)
                var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
                if (isPhone.test(mobile)) {
                    return true
                } else {
                    return false
                }

            },
        }

    }
</script>
<style>
    #app {
        background-color: #F2F5F7;
    }

    .box {
        width: 1360px;

        background: #FFFFFF;
        border-radius: 20px;
        opacity: 1;
        padding: 30px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar-uploader-iconA {
        font-size: 28px;
        color: #8c939d;
        width: 300px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .submit {
        width: 200px;
        height: 50px;
        background: #38DCA2;
        border-radius: 25px;
        line-height: 50px;
        text-align: center;
        margin-top: 86px;
        color: #FFFFFF;
    }

    .submit:hover {
        cursor: pointer;
        font-weight: 600;
    }

    .tel

    /*谷歌*/
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    /*火狐*/
    .tel input[type="number"] {
        -moz-appearance: textfield;
    }
</style>